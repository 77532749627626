<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>批发销售/出库单</span>
            </div>

            <!-- 批发销售单-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToSaleOrder"
                            class="font1"
                            :disabled="!hasOpenSaleOrderPrivilege"
                        >
                            批发销售单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateSaleOrder"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可新建批发销售单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewSaleOrder"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核批发销售单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockSaleOrder"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToSaleOrder"
                            :disabled="!hasCreateSaleOrderPrivilege"
                            >①新建批发销售单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择批发类型(采购订单为进货方使用的本系统，单方销售为进货发不使用本系统)
                            -选择进货机构(进货方)-点击选择商品基本信息-选择需要销售的商品-点击确定
                            -填写批发数量(批发价、赠品数量可修改)
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToSaleOrder"
                            :disabled="!hasReviewSaleOrderPrivilege"
                            >②审核批发销售单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        勾选需要审核的批发销售单-点击审核-审核成功后可以出库
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToSaleOrder"
                            :disabled="!hasStockSaleOrderPrivilege"
                            >③装箱后进行出库</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        勾选需要出库的单据-点击出库-完成后出库的商品会扣除商品库存
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToSaleOrder"
                            :disabled="!hasReviewSaleOrderPrivilege"
                            >④出库数量/批发价不正确</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        如审核后发现数量/批发价有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核
                        -确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核批发销售单操作(如果订单商品被出库，则无法反审核)
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const saleOrderPrivilegeFlag = 'menu.sale.order';
const openSaleOrderPrivilegeFlag = `${saleOrderPrivilegeFlag}.open`;
const createSaleOrderPrivilegeFlag = `${saleOrderPrivilegeFlag}.create`;
const reviewSaleOrderPrivilegeFlag = `${saleOrderPrivilegeFlag}.review`;

const saleOutOrderPrivilegeFlag = `menu.sale.out`;
const stockSaleOrderPrivilegeFlag = `${saleOutOrderPrivilegeFlag}.stock`;

export default {
    name: 'SaleOrderGuide',
    data() {
        return {
            rolesForCreateSaleOrder: [],
            rolesForReviewSaleOrder: [],
            rolesForStockSaleOrder: [],
        };
    },
    created() {
        //查询能够新建批发销售单据的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createSaleOrderPrivilegeFlag).then((rst) => {
            this.rolesForCreateSaleOrder = rst;
        });
        //查询能够审核批发销售单据的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewSaleOrderPrivilegeFlag).then((rst) => {
            this.rolesForReviewSaleOrder = rst;
        });
        //查询能够出入库批发销售单据的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockSaleOrderPrivilegeFlag).then((rst) => {
            this.rolesForStockSaleOrder = rst;
        });
    },
    computed: {
        roleNamesForCreateSaleOrder() {
            return this.rolesForCreateSaleOrder.map((e) => e.name).join('，');
        },
        roleNamesForReviewSaleOrder() {
            return this.rolesForReviewSaleOrder.map((e) => e.name).join('，');
        },
        roleNamesForStockSaleOrder() {
            return this.rolesForStockSaleOrder.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToSaleOrder() {
            Util.nameJump(this, saleOrderPrivilegeFlag);
        },
        hasOpenSaleOrderPrivilege() {
            return this.hasPrivilege(openSaleOrderPrivilegeFlag);
        },
        hasCreateSaleOrderPrivilege() {
            return this.hasPrivilege(createSaleOrderPrivilegeFlag);
        },
        hasReviewSaleOrderPrivilege() {
            return this.hasPrivilege(reviewSaleOrderPrivilegeFlag);
        },
        hasStockSaleOrderPrivilege() {
            return this.hasPrivilege(stockSaleOrderPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
